<template>
  <div class="n-footer">
    <div class="container">
      <!-- 上层导航 -->
      <div class="f-s">
        <a href="/">网址首页</a>
        <span>|</span>
        <a href="/#/ac1">收费资讯</a>
        <span>|</span>
        <a href="/#/info">关于我们</a>
        <!-- <span>|</span> -->
        <!-- <a href="/#/xy">用户协议</a> -->
      </div>

      <p>北京京师勤思教育科技有限公司</p>
      <p>
        <span>公司地址：北京市西城区德胜门外大街甲10号1幢9层903</span>
        <span>公司电话：010-86466160</span>
        <span>公司邮箱：kefu@qsiedu.com</span>
      </p>
      <p>
        <a href="https://beian.miit.gov.cn/#/Integrated/index"
          >备案号：京ICP备09114871号-3</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.n-footer {
  width: 100%;
  height: 150px;
  position: relative;
  // background-color: #eee;
  background-color: #fff;
  padding-top: 15px;
  box-sizing: border-box;
  .container {
    height: 100%;
    // 上层
    .f-s {
      a {
        font-size: 14px;
        color: #888;
      }
      span {
        font-size: 14px;
        color: #888;
        margin: 0 15px;
      }
    }
    p {
      font-size: 12px;
      color: #888;
      margin: 15px 0;
      a {
        font-size: 12px;
        color: #888;
      }
      span {
        font-size: 12px;
        margin-right: 10px;
      }
    }
  }
}
</style>