<template>
  <div class="index">
    <div class="banner">
      <div class="container"><img src="/imgs/b4.jpg" alt="" /></div>
    </div>
    <div class="container">
      <!-- 左侧 -->
      <div class="l">
        <!-- 资讯区块1 -->
        <div class="a1">
          <!-- 标题 -->
          <div class="a1-title">
            <h2>收费资讯</h2>
            <h4></h4>
          </div>

          <!-- 循环项 -->
          <div
            class="a1-item"
            v-for="(item, index) in this.d1"
            :key="index"
            @click="goDays(item.methods, item.id)"
          >
            <!-- 标题 -->
            <div class="title">
              {{ item.title }}
            </div>
            <!-- 中层 -->
            <div class="item-con">
              <img :src="item.img" alt="" />
              <div class="item-jj">
                {{ item.jj }}
              </div>
            </div>
            <!-- 信息层 -->
            <div class="item-info">
              <!-- 左侧 -->
              <div class="item-l">
                <span>{{ item.author }}</span>
                <span> ({{ item.sub }})阅读 </span>
                <span>{{ item.pay }}</span>
              </div>
              <!-- 右侧 -->
              <div class="item-r">{{ item.time }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧 -->
      <div class="r">
        <!-- 资讯3 -->
        <div class="a3">
          <!-- 标题 -->
          <div class="a3-title"><h2>推荐阅读</h2></div>
          <!-- 循环项 -->
          <div
            class="a3-item"
            v-for="(item, index) in this.d2"
            :key="index"
            @click="goDays(item.methods, item.id)"
          >
            <div class="item-id">{{ item.id }}</div>
            <div class="item-title">{{ item.title }}</div>
          </div>
        </div>

        <div class="a3">
          <!-- 标题 -->
          <div class="a3-title"><h2>其他资讯</h2></div>
          <!-- 循环项 -->
          <div
            class="a3-item"
            v-for="(item, index) in this.d3"
            :key="index"
            @click="goDays(item.methods, item.id)"
          >
            <div class="item-id">{{ item.id }}</div>
            <div class="item-title">{{ item.title }}</div>
          </div>
        </div>
      </div>


      
      <modal
        title="请选择支付方式"
        btnType="1"
        modalType="middle"
        :showModal="showModal"
        @submit="showModal = false"
        @cancel="showModal = false"
      >
        <template v-slot:body>
          <img
            style="display: flex; justify-content: center; margin: 0 auto"
            src="/imgs/pay2.png"
          />
          <h2>微信支付：2元/篇</h2>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import Modal from "./../components/Modal";
export default {
  name: "index",
  components: { Modal },
  data() {
    return {
      d1: [],
      d2: [],
      d3: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init();
    this.init2();
    document.title = "勤思教育";
  },
  methods: {
    init() {
      this.axios.get("/mock/info.json").then((res) => {
        const data = res.data;
        // 两条数据
        this.d1 = data.slice(0, 10);

        // console.log(this.data);
      });
    },
    init2() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        // 两条数据
        this.d2 = data.slice(4, 15);
        this.d3 = data.slice(8, 20);
        // console.log(this.data);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
      }
      // 未登录
      if (this.state !== 0) {
        alert("收费资讯，2元/篇");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  width: 100%;
  position: relative;
  background: #f2f2f2;

  //   banner
  .banner {
    width: 100%;
    height: 400px;
    margin: 20px 0;
    .container {
      height: 100%;
      // background: pink;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .container {
    // height: 1000px;
    // @include flex();
    display: flex;
    justify-content: space-between;
    // padding-top: 30px;
    box-sizing: border-box;
    // 左侧
    .l {
      width: 70%;
      height: 100%;
      background-color: #fff;
      padding: 0 15px;
      box-sizing: border-box;
      //   background-color: #ff6600;

      //   banner
      .banner {
        width: 100%;
        height: 300px;
        background-color: #fff;
      }

      //   资讯区块1
      .a1 {
        width: 100%;
        // height: 600px;
        background-color: #fff;
        margin-top: 30px;
        // 标题
        .a1-title {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #ccc;
          h2 {
            color: #333;
          }
        }

        // 循环项
        .a1-item {
          width: 100%;
          height: 200px;
          padding: 5px;
          box-sizing: border-box;
          margin: 30px 0;
          transition: all 0.2s;
          padding: 10px;
          box-sizing: border-box;
          &:hover {
            box-shadow: 0px 0px 8px #888;
            transform: translateY(-10px);
            .title {
              color: $colorZ;
            }
          }
          //   标题
          .title {
            width: 100%;
            font-size: 14px;
            color: #333;
            font-weight: 400;
            cursor: pointer;
            transition: all 0.2s;
            &:hover {
              color: $colorZ;
            }
          }
          //   中层
          .item-con {
            width: 100%;
            height: 120px;
            margin: 10px 0;
            // background: #ff5544;
            display: flex;
            align-items: center;
            // 图品
            img {
              width: 200px;
              height: 100%;
            }
            .item-jj {
              margin-left: 20px;
              width: 100%;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              font-size: 12px;
              color: #888;
              line-height: 30px;
            }
          }
          //   信息层
          .item-info {
            height: 30px;
            width: 100%;
            // background-color: #451215;
            @include flex();
            .item-l {
              span {
                color: #888;
                font-size: 12px;
                margin-right: 30px;
                i {
                  font-size: 14px;
                  margin-right: 5px;
                }
              }
            }
            .item-r {
              font-size: 12px;
              color: #888;
            }
          }
        }
      }
    }
    // 右侧
    .r {
      width: 30%;
      height: 100%;
      margin-left: 30px;
      background-color: #fff;
      padding: 0 15px;
      box-sizing: border-box;

      .banner {
        width: 100%;
        height: 200px;
        margin-bottom: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .a3 {
        margin: 30px 0;
        //   标题
        .a3-title {
          width: 100%;
          height: 40px;
          // padding: 20px 0;
          h2 {
            //   font-size: 16px;
            color: #333;
            font-weight: 400;
            border-bottom: 1px solid #ccc;
            line-height: 30px;
            height: 100%;
          }
        }

        //   循环项
        .a3-item {
          width: 100%;
          height: 40px;
          margin: 15px 0;
          display: flex;
          align-items: center;
          &:hover {
            .item-title {
              color: $colorZ;
            }
          }
          &:nth-child(2) {
            .item-id {
              background: #c60023;
            }
          }
          &:nth-child(3) {
            .item-id {
              background: #ff6600;
            }
          }
          &:nth-child(4) {
            .item-id {
              background: #f5d526;
            }
          }
          // ID图标
          .item-id {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #ccc;
            text-align: center;
            line-height: 30px;
            font-size: 16px;
            color: #fff;
            margin-right: 15px;
          }

          .item-title {
            width: 80%;
            // background-color: #c60023;
            font-size: 14px;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            transition: all 0.2s;
          }
        }
      }
    }
  }
}
</style>