<template>
  <div class="info">
    <!-- 定宽 -->
    <div class="container">
      <div class="banner">
        <img src="/imgs/b4.jpg" alt="" />
      </div>
      <!-- 右侧 内容 -->
      <div class="info-2">
        <h3>勤思教育</h3>
        <!-- 下环线 -->
        <div class="bink"></div>

        <div class="i2-con">
          <p>
            北京京师勤思教育科技有限公司，简称勤思教育，成立于2009年，目前公司以考研专业课辅导为核心，以考研公共课辅导为支柱，紧紧依托自身优势，开拓进取，不断创新，已成为一家集教育产品研发，教育服务于一体的综合性教育科技公司。
          </p>
          <p>企业文化：诚信、专注、责任心、利他、超越
</p>
          <p>
            企业精神：业精于勤，行成于思
          </p>
          <p>旗下品牌</p>
          <p>勤思考研：考研辅导范围囊括国内多所研究生招生高等院校，专业包括教育学、心理学、汉语国际教育硕士等专业。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "info",
  components: {},
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "勤思教育";
  },
  methods: {
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.info {
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  background-color: #f0f0f0;
  // banner

  // 定宽布局
  .container {
    //banner
    .banner {
      width: 100%;
      height: 400px;
      background-color: pink;

      img {
        width: 100%;
        height: 100%;
      }
    }
    // 左侧
    .info-1 {
      width: 30%;
      height: 100%;
      margin-right: 20px;
      // background-color: red;
      ul {
        width: 190px;
        background-color: #fff;
        border-bottom: 2px solid #dfe1e2;
        border-radius: 3px;
        padding: 13px 15px;
        margin: 0 auto;
        li {
          width: 90%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: bold;

          box-sizing: border-box;
          margin: 10px auto;
          cursor: pointer;
          padding-left: 20px;
          &.active {
            background-color: #3890e1;
            color: #fff;
          }
        }
      }
    }
    // 右侧
    .info-2 {
      width: 100%;
      height: 100%;
      background: #fff;
      padding-top: 20px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 60px;
      h3 {
        font-size: 24px;
      }
      // 下划线
      .bink {
        width: 30px;
        height: 3px;
        background-color: #000;
        margin: 10px auto;
      }

      // banner
      .i2-banner {
        width: 100%;
        height: 300px;
        background: url("/imgs/b1.jpg") no-repeat center;
        background-size: cover;
        margin: 30px auto;
      }

      // 文章
      .i2-con {
        // width: 600px;
        text-align: left;
        padding: 0 20px;
        box-sizing: border-box;
        p {
          font-size: 16px;
          line-height: 1.3em;
          color: #666;
          margin: 15px auto;
          text-indent: 30px;
          line-height: 41px;
          a {
            color: #666;
          }
        }
      }
    }
  }
}
</style>
