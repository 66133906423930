<template>
  <div class="login">
    <!-- 中间主体 -->
    <div class="wrapper">
      <div class="container">
        <!-- 表单容器 -->
        <div class="login-form">
          <!-- 头部 -->
          <h3>
            <span class="checked">账号注册</span>
          </h3>
          <!-- 主体 搜索框 -->
          <div class="input">
            <input type="text" placeholder="请输入账号" v-model="rUsername" />
            <i class="iconfont icon-denglu"></i>
          </div>
          <div class="input">
            <input type="text" placeholder="密码" v-model="rPassword" />
            <i class="iconfont icon-jinru"></i>
          </div>

          <!-- 登录按钮 -->
          <div class="btn-large">
            <a href="javascript:;" class="btn" @click="register()">注册</a>
          </div>

          <!-- 提示 -->
          <div class="tips">
            <div class="reg" @click="goLogin()">已有账号,去登录</div>
            <!-- <a href="/#/xy" class="reg">《用户协议》</a> -->
          </div>
        </div>

        <!-- 右侧介绍 -->
        <!-- <div class="info">
          <h2>北京丰梵科技有限公司</h2>
          <h4>Beijing Fengfan Technology Co., Ltd</h4>
          <p>
            公司经营范围包括技术开发；技术咨询；技术转让；技术推广；技术服务；软件开发；基础软件服务；应用软件服务；计算机系统服务；数据处理（数据处理中的银行卡中心、PUE值在1.4以上的云计算数据中心除外）等...
          </p>
          <p>网站网址：bjfenglkjicp.cn</p>
          <p>公司地址：北京市顺义区北小营镇小胡营村6号院3幢1层136室</p>
          <p>
            网址备案：<a href="https://beian.miit.gov.cn/#/Integrated/index"
              >京ICP备2020036906号-1</a
            >
          </p>
        </div> -->
      </div>
    </div>

    <!-- 尾部 -->
  </div>
</template>

<script>
export default {
  name: "login",
  components: {},
  data() {
    return {
      href: "index.html",
      rUsername: "",
      rPassword: "",
      logo_user: JSON.parse(window.localStorage.getItem("user")),
    };
  },
  mounted() {
    document.title = "勤思教育";
  },
  methods: {
    // 注册逻辑
    register() {
      // 获取 user
      var ruser = {
        Username: this.rUsername,
        Password: this.rPassword,
      };
      // 判断非空
      if (!this.rUsername) {
        alert("请输入用户名");
        return;
      }
      if (!this.rPassword) {
        alert("请输入密码");
        return;
      }
      window.localStorage.setItem("state", "0");
      window.localStorage.setItem("user", JSON.stringify(ruser));
      this.$router.push("/#/index");
      window.location.reload();
    },
    goLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import "./../assets/scss/button.scss";
.login {
  // margin-top: 80px;
  // padding: 20px 0;
  box-sizing: border-box;
  // header
  & > .container {
    height: 113px;
    img {
      width: auto;
      height: 100%;
    }
  }
  // 中间主体
  .wrapper {
    background: url("/imgs/ht.jpg") no-repeat center;
    background-size: cover;
    // background-color: pink;
    .container {
      height: 576px;
      display: flex;
      justify-content: space-between;
      // 表单容器
      .login-form {
        position: absolute;
        right: 0;
        bottom: 29px;
        width: 410px;
        height: 450px;
        // background-color: #eee;
        background-color: rgba(255, 255, 255, 0.8);
        box-sizing: border-box;
        padding-left: 31px;
        padding-right: 31px;
        // border-radius: 30px;
        // 头部
        h3 {
          line-height: 23px;
          font-size: 24px;
          text-align: center;
          margin: 40px auto 49px;
          // 左侧字体
          .checked {
            color: $colorZ;
          }
          // 右侧字体
          .sep-line {
            margin: 0 32px;
          }
        }

        // input
        .input {
          display: inline-block;
          width: 348px;
          height: 50px;
          border: 1px solid #e5e5e5;
          margin-bottom: 20px;
          display: flex;
          i {
            width: 15%;
            height: 100%;
            /* background-color: pink; */
            display: inline-block;
            line-height: 44px;
            font-size: 31px;
            text-align: center;
            border-radius: 1px solid #666;
            border: 1px solid #ccc;
            background-color: #fff;
          }
          input {
            width: 80%;
            height: 100%;
            border: none;
            padding: 18px;
          }
        }

        // 登录按钮
        .btn {
          width: 100%;
          line-height: 50px;
          font-size: 16px;
          margin-top: 10px;
          background: $colorZ;
          border-radius: 30px;
        }

        // 提示
        .tips {
          display: flex;
          justify-content: space-between;
          margin-top: 14px;
          cursor: pointer;
          font-size: 14px;
          .sms {
            color: #ff6600;
          }
          .reg {
            color: #999999;
            span {
              margin: 0 7px;
              color: #999999;
            }
          }
        }
      }
      .info {
        width: 500px;
        height: 350px;
        background: rgba(255, 255, 255, 0.8);
        position: absolute;
        left: 90px;
        bottom: 29px;
        padding: 30px;
        box-sizing: border-box;
        // border-radius: 30px;
        h2 {
          font-size: 21px;
          color: #000;
        }
        h4 {
          color: #000;
          font-size: 16px;
          margin: 10px 0;
        }

        p {
          font-size: 12px;
          color: #000;
          line-height: 25px;
          font-weight: bold;
        }

        p {
          font-size: 14px;
          color: #000;
          margin: 20px 0;
          a {
            color: #000;
          }
        }
      }
    }
  }
  .btn-large {
    margin-bottom: 20px;
    margin: 20px auto;
  }
}
</style>
